import React from 'react';
import './App.css';
import {LocaleContextHook, LocaleContextProvider} from "./Libraries/Contexts/LocaleContext";
import {TranslationContextHook, TranslationContextProvider} from "./Libraries/Contexts/TranslationContext";
import Translator from "./Libraries/Utils/Translator";
import Router from "./Router";
import {ThemeCache} from "./Libraries/Caches/System/ThemeCache";
import {syncTheme} from "./Libraries/Theme/Theme";

function App() {
  const [locale, dispatchLocale] = LocaleContextHook();
  const [translation, dispatchTranslation] = TranslationContextHook();
  const {theme} = ThemeCache();

  syncTheme(theme);
  Translator.loadTranslation(dispatchTranslation);
  document.title = `Queube - Homepage`;

  return <>
    {/*@ts-ignore*/}
    <LocaleContextProvider value={{locale, dispatchLocale}}>
      {/*@ts-ignore*/}
      <TranslationContextProvider value={{translation, dispatchTranslation}}>
        <Router/>
      </TranslationContextProvider>
    </LocaleContextProvider>
  </>;
}

export default App;
