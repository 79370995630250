import {
    backgroundColor,
    border,
    classes, cursor,
    flex,
    height,
    margin,
    padding, round,
    textAlign, textColor, textSize,
    width
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_SECTION_3_CREATE_OWNER_ACCOUNT_BUTTON,
    CTA_SECTION_3_TO_BO_LINK, CTA_SECTION_4_TO_CLIENT_LINK, LABEL_SECTION_3_CREATE_OWNER_SUCCESS_TIMER,
    LABEL_SECTION_3_TRY_QUESTION,
    LABEL_SECTION_4_PROCESS_ITEM_0,
    LABEL_SECTION_4_PROCESS_ITEM_1,
    LABEL_SECTION_4_PROCESS_ITEM_2, LABEL_SECTION_4_PROCESS_ITEM_3,
    LABEL_SECTION_4_PROCESS_ITEM_4,
    LABEL_SECTION_4_TITLE, LABEL_SECTION_4_TRY_QUESTION,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import {getIcon} from "../Libraries/Helpers/Icon";
import Button from "../Libraries/Components/Common/Button/Button";
import SectionsState from "./SectionsState";
import {useEffect, useState} from "react";
import InputLogin from "../Libraries/Components/Domain/Owner/Common/Form/Input/InputLogin";
import InputEmail from "../Libraries/Components/Domain/Owner/Common/Form/Input/InputEmail";
import InputPassword from "../Libraries/Components/Domain/Owner/Common/Form/InputPassword/InputPassword";
import {ADMIN_URL, CLIENT_URL} from "../Constants/Server";
import LocaleCache from "../Libraries/Caches/System/LocaleCache";
import {ThemeCache} from "../Libraries/Caches/System/ThemeCache";
import {sendRedirectUserInterfaceEvent} from "../Libraries/GoogleAnalytics/Events";
import Modal from "../Libraries/Components/Common/Modal/Modal";

function Section4() {
    const themeCache = ThemeCache();
    const localeCache = new LocaleCache();
    const {scrollToRef, section4Ref, section5Ref} = SectionsState();
    const [selectedProcess, setSelectedProcess] = useState<number|undefined>(undefined);
    const [imageOpened, setImageOpened] = useState(false);

    const clientUrl = `${CLIENT_URL}?lang=${localeCache.get()}&theme=${themeCache.theme}`;

    useEffect(() => {
        if (selectedProcess === undefined) {
            setSelectedProcess(0);
        }
    }, []);

    const selectProcess = (processNumber: number) => {
        setSelectedProcess(() => processNumber);
    };

    const handleCheckUserInterfaceButton = () => {
        sendRedirectUserInterfaceEvent(undefined);
        window.open(clientUrl, '_blank');
    };

    return <section ref={section4Ref} className={classes(backgroundColor('ternary'), textColor('ternary'), height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), padding('x', '10px'))}>
        {selectedProcess !== undefined && <Modal opened={imageOpened} onClickModalBackground={() => setImageOpened(() => false)}>
            <img onClick={() => setImageOpened(() => true)} className={classes(width('100%', 'max'), height('80vh', 'max'))} src={`https://s3.eu-west-3.amazonaws.com/static.queube.fr/user${selectedProcess+1}-${themeCache.theme}.png`}/>
        </Modal>}
        <div className={classes(width('100%'))}>
            <LabelNewLine textSizeValue={'30px'} label={LABEL_SECTION_4_TITLE} underlineValue={true} className={classes(margin('y', '30px'), textAlign("center"))}/>
            <div className={classes(flex("center", "center", '10px', true, 'md'), padding('x', '20px'), width('100%'))}>
                <div className={classes(width('50%', 'max', 'md'), width('500px', '', 'md'), width('100%'), flex("center", "center"))}>
                    <div className={classes(backgroundColor("secondary"), border(), round(), padding('xy', '10px'), width('100%'), flex("center", "center"))}>{selectedProcess !== undefined && <img onClick={() => setImageOpened(() => true)} className={classes(round(), cursor("pointer"))} src={`https://s3.eu-west-3.amazonaws.com/static.queube.fr/user${selectedProcess+1}-${themeCache.theme}.png`}/>}</div>
                </div>
                <div className={classes(width('50%', 'max', 'md'), width('500px', '', 'md'), width('100%'), margin('t', '30px'), margin('t', '0', 'md'))}>
                    <Button onClick={() => selectProcess(0)} label={LABEL_SECTION_4_PROCESS_ITEM_0} className={classes(width('100%'), padding('y', '10px', '', true))} color={'primary'} selected={selectedProcess === 0}/>
                    <Button onClick={() => selectProcess(1)} label={LABEL_SECTION_4_PROCESS_ITEM_1} className={classes(width('100%'), margin('t', '10px'), padding('y', '10px', '', true))} color={'primary'} selected={selectedProcess === 1}/>
                    <Button onClick={() => selectProcess(2)} label={LABEL_SECTION_4_PROCESS_ITEM_2} className={classes(width('100%'), margin('t', '10px'), padding('y', '10px', '', true))} color={'primary'} selected={selectedProcess === 2}/>
                    <Button onClick={() => selectProcess(3)} label={LABEL_SECTION_4_PROCESS_ITEM_3} className={classes(width('100%'), margin('t', '10px'), padding('y', '10px', '', true))} color={'primary'} selected={selectedProcess === 3}/>
                    <Button onClick={() => selectProcess(4)} label={LABEL_SECTION_4_PROCESS_ITEM_4} className={classes(width('100%'), margin('t', '10px'), padding('y', '10px', '', true))} color={'primary'} selected={selectedProcess === 4}/>
                </div>
            </div>
            <LabelNewLine label={LABEL_SECTION_4_TRY_QUESTION} className={classes(textSize("20px"), textAlign("center"), margin('t', '30px'))}/>
            <div className={classes(flex('center', "center"))}>
                <div>
                    <div className={classes(flex("center", "center"), margin('t', '30px'), width('300px'))}>
                        <Button onClick={handleCheckUserInterfaceButton} label={CTA_SECTION_4_TO_CLIENT_LINK} className={classes(padding('xy', '10px', '', true), width('100%'))}/>
                    </div>
                </div>
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button onClick={() => scrollToRef(section5Ref)} label={getIcon('ChevronDoubleDown', 'medium')} color={'primary'}/>
            </div>
        </div>
    </section>;
}

export default Section4;