import {
    backgroundColor, boldText,
    border, borderColor, borderSize,
    classes, cursor,
    flex,
    height,
    margin,
    padding, position, round,
    textAlign, textColor, textSize,
    width
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_SECTION1_BE_OWNER,
    CTA_SECTION1_BE_USER,
    LABEL_SECTION1_DESCRIPTION,
    LABEL_SECTION1_PROCESS1_DESCRIPTION,
    LABEL_SECTION1_PROCESS2_DESCRIPTION,
    LABEL_SECTION1_PROCESS3_DESCRIPTION,
    LABEL_SECTION1_TRY,
    LABEL_SECTION_3_TITLE,
    LABEL_SECTION_5_BORIS_CONTACT,
    LABEL_SECTION_5_BORIS_EXP,
    LABEL_SECTION_5_BORIS_GRADE, LABEL_SECTION_5_BORIS_IMG,
    LABEL_SECTION_5_BORIS_TITLE,
    LABEL_SECTION_5_DESIGNER_CONTACT,
    LABEL_SECTION_5_DESIGNER_EXP,
    LABEL_SECTION_5_DESIGNER_TITLE,
    LABEL_SECTION_5_DEVELOPER_CONTACT,
    LABEL_SECTION_5_DEVELOPER_EXP,
    LABEL_SECTION_5_DEVELOPER_TITLE,
    LABEL_SECTION_5_DEVOPS_CONTACT,
    LABEL_SECTION_5_DEVOPS_EXP,
    LABEL_SECTION_5_DEVOPS_TITLE,
    LABEL_SECTION_5_HIRE_TITLE, LABEL_SECTION_5_KAM_CONTACT, LABEL_SECTION_5_KAM_EXP, LABEL_SECTION_5_KAM_TITLE,
    LABEL_SECTION_5_NICOLAS_CONTACT,
    LABEL_SECTION_5_NICOLAS_EXP,
    LABEL_SECTION_5_NICOLAS_GRADE, LABEL_SECTION_5_NICOLAS_IMG,
    LABEL_SECTION_5_NICOLAS_TITLE,
    LABEL_SECTION_5_TITLE,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import {getIcon} from "../Libraries/Helpers/Icon";
import Button from "../Libraries/Components/Common/Button/Button";
import SectionsState from "./SectionsState";
import Label from "../Libraries/Components/Common/Label/Label";
import {sendRedirectLinkedInEvent} from "../Libraries/GoogleAnalytics/Events";
import {Translations} from "../Libraries/Components/Common/Menu/MenuLink/Links/MenuLinkTranslations.stories";
import {getLabel} from "../Libraries/Helpers/Label";

function Section5() {
    const {scrollToRef, section5Ref, section6Ref} = SectionsState();

    const profiles: {
        firstname: string,
        lastname: string,
        title: {id: string, variables: any},
        grade: {id: string, variables: any},
        picture: string,
        linkedIn: string,
        experience: {id: string, variables: any},
        contact: {id: string, variables: any},
    }[] = [
        {
            firstname: 'Boris',
            lastname: 'YU',
            title: LABEL_SECTION_5_BORIS_TITLE,
            grade: LABEL_SECTION_5_BORIS_GRADE,
            experience: LABEL_SECTION_5_BORIS_EXP,
            picture: getLabel(LABEL_SECTION_5_BORIS_IMG),
            linkedIn: 'https://www.linkedin.com/in/borisyu75/',
            contact: LABEL_SECTION_5_BORIS_CONTACT,
        },
        {
            firstname: 'Nicolas',
            lastname: 'CHUNG',
            title: LABEL_SECTION_5_NICOLAS_TITLE,
            grade: LABEL_SECTION_5_NICOLAS_GRADE,
            picture: getLabel(LABEL_SECTION_5_NICOLAS_IMG),
            linkedIn: 'https://www.linkedin.com/in/nicolas-chung-a67a88130/',
            experience: LABEL_SECTION_5_NICOLAS_EXP,
            contact: LABEL_SECTION_5_NICOLAS_CONTACT,
        },
    ];

    const tbhs: {
        experience: {id: string, variables: any},
        title: {id: string, variables: any},
        contact: {id: string, variables: any},
    }[] = [
        {
            experience: LABEL_SECTION_5_DEVOPS_EXP,
            title: LABEL_SECTION_5_DEVOPS_TITLE,
            contact: LABEL_SECTION_5_DEVOPS_CONTACT,
        },
        {
            experience: LABEL_SECTION_5_DESIGNER_EXP,
            title: LABEL_SECTION_5_DESIGNER_TITLE,
            contact: LABEL_SECTION_5_DESIGNER_CONTACT,
        },
        {
            experience: LABEL_SECTION_5_DEVELOPER_EXP,
            title: LABEL_SECTION_5_DEVELOPER_TITLE,
            contact: LABEL_SECTION_5_DEVELOPER_CONTACT,
        },
        {
            experience: LABEL_SECTION_5_KAM_EXP,
            title: LABEL_SECTION_5_KAM_TITLE,
            contact: LABEL_SECTION_5_KAM_CONTACT,
        },
    ];

    const handleCheckLinkedInButton = (link: string) => {
        sendRedirectLinkedInEvent(undefined, link);
        window.open(link, '_blank');
    };

    return <section ref={section5Ref} className={classes(backgroundColor('primary'), textColor('primary'), height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), padding('x', '10px'), padding('b', '30px'))}>
        <div className={classes(width('100%'))}>
            <LabelNewLine textSizeValue={'30px'} label={LABEL_SECTION_5_TITLE} underlineValue={true} className={classes(margin('y', '30px'), textAlign("center"))}/>
            <div className={classes(flex("center", "center", '20px'), padding('x', '20px'))}>
                {profiles.map((profile: any, index: number) => <div key={`profile-${index}`} className={classes(width('300px'))}>
                    <div className={classes(flex("center", "center"))}>
                        <div className={classes(round("full"), borderSize('5px'))}>
                            <img src={profile.picture} className={classes(round("full"))}/>
                        </div>
                    </div>
                    <div className={classes(flex("center", 'center'), 'mt-[-50px]', margin('b', '10px'))}>
                        <div onClick={() => handleCheckLinkedInButton(profile.linkedIn)} className={classes(padding('xy', '10px'), border(), round("full"), borderColor("ternary"), backgroundColor("ternary"), 'ml-[130px]', cursor("pointer"), textColor("ternary"))}>
                            {getIcon('Link', 'small')}
                        </div>
                    </div>
                    <div className={classes(flex("center", "center"), margin('t', '-100px'), padding('t', '100px'), padding('b', '10px'), border(), borderColor('primary'), round(), backgroundColor('secondary'))}>
                        <div>
                            <div className={classes(boldText())}>
                                <Label label={profile.firstname} className={classes(textSize('20px'), textAlign("center"))}/> <Label label={profile.lastname} className={classes(textSize('20px'))}/>
                            </div>
                            <LabelNewLine label={profile.grade} className={classes(textAlign('center'))}/>
                            <LabelNewLine label={profile.title} className={classes(textAlign('center'))}/>
                            <LabelNewLine label={profile.experience} className={classes(textAlign('center'))}/>
                            <LabelNewLine label={profile.contact} className={classes(textAlign('center'))}/>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>)}
            </div>
            <LabelNewLine textSizeValue={'20px'} label={LABEL_SECTION_5_HIRE_TITLE} underlineValue={true} className={classes(margin('y', '30px'), textAlign("center"))}/>
            <div className={classes(flex("center", "center", '20px'), padding('x', '20px'))}>
                {tbhs.map((profile: any, index: number) => <div key={`tbh-${index}`} className={classes(width('300px'))}>
                    <div className={classes(flex("center", "center"))}>
                        <div className={classes(round("full"), borderSize('5px'))}>
                            <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8lCCz3naP8eDnFr476DeuRV8FvxmratoD6q-yuxjKsMu-0DQCKMzH5PeTAk6qB7UyxmE&usqp=CAU'} className={classes(round("full"))}/>
                        </div>
                    </div>
                    <div className={classes(flex("center", "center"), margin('t', '-100px'), padding('t', '100px'), padding('b', '10px'), border(), borderColor('primary'), round(), backgroundColor('secondary'))}>
                        <div>
                            <LabelNewLine label={profile.title} className={classes(textAlign('center'), boldText(), textSize('20px'))}/>
                            <LabelNewLine label={profile.experience} className={classes(textAlign('center'))}/>
                            <LabelNewLine label={profile.contact} className={classes(textAlign('center'))}/>
                        </div>
                    </div>
                </div>)}
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button type={"submit"} onClick={() => scrollToRef(section6Ref)} label={getIcon('ChevronDoubleDown', 'medium')} color={'ternary'}/>
            </div>
        </div>
    </section>;
}

export default Section5;