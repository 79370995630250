import {
    backgroundColor,
    border,
    classes,
    flex,
    height,
    margin,
    padding, round,
    textAlign, textColor,
    width
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_SECTION1_ACCESS_STORYBOOK,
    CTA_SECTION1_BE_OWNER, CTA_SECTION1_BE_USER,
    LABEL_SECTION1_DESCRIPTION,
    LABEL_SECTION1_PROCESS1_DESCRIPTION,
    LABEL_SECTION1_PROCESS2_DESCRIPTION,
    LABEL_SECTION1_PROCESS3_DESCRIPTION,
    LABEL_SECTION1_TRY, LABEL_SECTION1_TRY_STORYBOOK,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import {getIcon} from "../Libraries/Helpers/Icon";
import Button from "../Libraries/Components/Common/Button/Button";
import SectionsState from "./SectionsState";
import {
    sendRedirectStorybookEvent,
    sendTryManagerInterfaceEvent,
    sendTryUserInterfaceEvent
} from "../Libraries/GoogleAnalytics/Events";
import {STORYBOOK_URL} from "../Constants/Server";

function Section1() {
    const {scrollToRef, section1Ref, section2Ref, section3Ref, section4Ref} = SectionsState();

    const handleTryManagerInterfaceButton = () => {
        sendTryManagerInterfaceEvent(undefined);
        scrollToRef(section3Ref);
    };

    const handleTryUserInterfaceButton = () => {
        sendTryUserInterfaceEvent(undefined);
        scrollToRef(section4Ref);
    };

    const handleCheckStorybookButton = () => {
        sendRedirectStorybookEvent(undefined);
        window.open(STORYBOOK_URL, '_blank');
    };

    return <section ref={section1Ref} className={classes(height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), backgroundColor('primary'))}>
        <div>
            <h1>
                <LabelNewLine label={'Queube'} textAlignValue={'center'} textSizeValue={'50px'}/>
            </h1>
            <h2>
                <LabelNewLine label={LABEL_SLOGAN} textAlignValue={'center'} textSizeValue={'30px'}/>
            </h2>
            <div className={classes(margin('y', '30px'))}>
                <LabelNewLine label={LABEL_SECTION1_DESCRIPTION} textAlignValue={'center'} textSizeValue={'15px'}/>
            </div>
            <div className={classes(flex("center", "center", '10px', true, 'sm'))}>
                <div className={classes(width('300px', "max", 'sm'), width('100%'), padding("xy", '10px'), margin('x', 'auto'))}>
                    <div className={classes(round(), border('xy'), padding('xy', '10px'), backgroundColor('secondary'))}>
                        <LabelNewLine label={LABEL_SECTION1_PROCESS1_DESCRIPTION} textAlignValue={'center'} textSizeValue={'15px'}/>
                        <div className={classes(flex("center", "center"), margin('t', '10px'))}>{getIcon('Shop', 'medium')}</div>
                    </div>
                </div>
                <div className={classes(width('300px', "max", 'sm'), width('100%'), padding("xy", '10px'), margin('x', 'auto'))}>
                    <div className={classes(round(), border('xy'), padding('xy', '10px'), backgroundColor('secondary'))}>
                        <LabelNewLine label={LABEL_SECTION1_PROCESS2_DESCRIPTION} textAlignValue={'center'} textSizeValue={'15px'}/>
                        <div className={classes(flex("center", "center"), margin('t', '10px'))}>{getIcon('Link', 'medium')}</div>
                    </div>
                </div>
                <div className={classes(width('300px', "max", 'sm'), width('100%'), padding("xy", '10px'), margin('x', 'auto'))}>
                    <div className={classes(round(), border('xy'), padding('xy', '10px'), backgroundColor('secondary'))}>
                        <LabelNewLine label={LABEL_SECTION1_PROCESS3_DESCRIPTION} textAlignValue={'center'} textSizeValue={'15px'}/>
                        <div className={classes(flex("center", "center"), margin('t', '10px'))}>{getIcon('CheckBadge', 'medium')}</div>
                    </div>
                </div>
            </div>
            <div className={classes(margin('t', '30px'))}>
                <LabelNewLine label={LABEL_SECTION1_TRY} textSizeValue={'20px'} textAlignValue={"center"}/>
            </div>
            <div className={classes(margin('t', '10px'), flex("center", "center", '10px', true, 'sm'), padding('xy', '10px'))}>
                <Button onClick={handleTryManagerInterfaceButton} size={"auto"} label={CTA_SECTION1_BE_OWNER} className={classes(width('250px', "max", 'sm'), width('100%'), margin('r', '0', 'sm'), padding('xy', '10px', '', true))}/>
                <Button onClick={handleTryUserInterfaceButton} size={"auto"} label={CTA_SECTION1_BE_USER} className={classes(width('250px', "max", 'sm'), width('100%'), margin('l', '0', 'sm'), margin('t', '10px'), margin('t', '0', 'sm'), padding('xy', '10px', '', true))}/>
            </div>
            <div className={classes(margin('t', '30px'))}>
                <LabelNewLine label={LABEL_SECTION1_TRY_STORYBOOK} textSizeValue={'20px'} textAlignValue={"center"}/>
            </div>
            <div className={classes(margin('t', '10px'), flex("center", "center", '10px', true, 'sm'), padding('xy', '10px'))}>
                <Button onClick={handleCheckStorybookButton} size={"auto"} label={CTA_SECTION1_ACCESS_STORYBOOK} className={classes(width('250px', "max", 'sm'), width('100%'), margin('r', '0', 'sm'), padding('xy', '10px', '', true))}/>
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button onClick={() => scrollToRef(section2Ref)} label={getIcon('ChevronDoubleDown', 'medium')} color={'ternary'}/>
            </div>
        </div>
    </section>;
}

export default Section1;