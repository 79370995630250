import {State} from "./State";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    display, flex,
    height, margin,
    position,
    width, zIndex
} from "../../Libraries/Theme/Theme";
import LocaleButton from "../../Libraries/Components/Common/Button/LocaleButton";
import ThemeButton from "../../Libraries/Components/Common/Button/ThemeButton";
import MenuSeparator from "../../Libraries/Components/Common/Menu/MenuSeparator/MenuSeparator";
import MenuLink from "../../Libraries/Components/Common/Menu/MenuLink/MenuLink";
import {
    CTA_SECTION_1_MENU,
    CTA_SECTION_2_MENU,
    CTA_SECTION_3_MENU,
    CTA_SECTION_4_MENU,
    CTA_SECTION_5_MENU,
    CTA_SECTION_6_MENU,
    CTA_SECTION_7_MENU,
    CTA_SECTION_8_MENU, CTA_SECTION_9_MENU,
    LABEL_SUB_LOGO_BACK_OFFICE
} from "../../Libraries/Constants/Labels";
import SectionsState from "../../Sections/SectionsState";
import Logo from "../../Libraries/Components/Common/Menu/Logo/Logo";

function Menu() {
    const {isOpened, toggle} = State();
    const {scrollToRef, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref, section8Ref, section9Ref} = SectionsState();

    return !isOpened ? null : <>
        <div className={classes(position('fixed'), position('relative', '', '', "md"), display('block'), display('none', 'md'), width('100%'), height('100vh'), backgroundColor('modal'), zIndex('500'))} onClick={toggle}></div>
        <div className={classes(border('r'), borderColor(), width('300px'), position('fixed', '0', '0'), height('100vh'), backgroundColor('secondary'), zIndex('550'))}>
            <Logo/>
            <MenuSeparator/>
            <MenuLink link={"#"} label={CTA_SECTION_1_MENU} onClick={() => scrollToRef(section1Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_2_MENU} onClick={() => scrollToRef(section2Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_3_MENU} onClick={() => scrollToRef(section3Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_4_MENU} onClick={() => scrollToRef(section4Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_5_MENU} onClick={() => scrollToRef(section5Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_6_MENU} onClick={() => scrollToRef(section6Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_7_MENU} onClick={() => scrollToRef(section7Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_8_MENU} onClick={() => scrollToRef(section8Ref)}/>
            <MenuLink link={"#"} label={CTA_SECTION_9_MENU} onClick={() => scrollToRef(section9Ref)}/>
            <MenuSeparator/>
            <div className={classes(flex("center", "center", '10px'), margin('t', '10px'))}>
                <LocaleButton alwaysShow={true}/>
                <ThemeButton alwaysShow={true}/>
            </div>
        </div>
    </>;
}

export default Menu;