export const API_URL = 'https://queube-api.zapto.org';
export const CLIENT_URL = 'http://dev-client.queube.fr';
export const QUEUBE_URL = 'http://dev-back-office.queube.fr';
    export const ADMIN_URL = 'http://dev-admin.queube.fr';
export const STORYBOOK_URL = 'http://dev-storybook.queube.fr';
export const ENVIRONMENT = 'PROD';

export const FEATURES = {
    client: {
        googleAnalyticsTrackingId: 'UA-273192187-1',
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'owner-admin': {
        googleAnalyticsTrackingId: 'UA-273192187-9',
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'queube-admin': {
        googleAnalyticsTrackingId: undefined,
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'client-front-maintenance': {
        googleAnalyticsTrackingId: 'UA-273192187-10',
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'storybook': {
        googleAnalyticsTrackingId: undefined,
        sendGoogleAnalyticsEvents: false,
        sendGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
};