import {
    backgroundColor, boldText,
    border,
    classes,
    flex,
    height,
    margin,
    padding, round,
    textAlign, textColor, textSize, underline,
    width
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_SECTION_3_CREATE_OWNER_ACCOUNT_BUTTON,
    CTA_SECTION_3_TO_BO_LINK,
    CTA_SECTION_4_TO_CLIENT_LINK,
    LABEL_SECTION_3_CREATE_OWNER_SUCCESS_TIMER,
    LABEL_SECTION_3_TRY_QUESTION,
    LABEL_SECTION_4_PROCESS_ITEM_0,
    LABEL_SECTION_4_PROCESS_ITEM_1,
    LABEL_SECTION_4_PROCESS_ITEM_2,
    LABEL_SECTION_4_PROCESS_ITEM_3,
    LABEL_SECTION_4_PROCESS_ITEM_4,
    LABEL_SECTION_4_TITLE,
    LABEL_SECTION_4_TRY_QUESTION,
    LABEL_SECTION_6_DESCRIPTION,
    LABEL_SECTION_6_DESCRIPTION2,
    LABEL_SECTION_6_PROCESS_ITEM_0,
    LABEL_SECTION_6_PROCESS_ITEM_1,
    LABEL_SECTION_6_PROCESS_ITEM_2,
    LABEL_SECTION_6_PROCESS_ITEM_3,
    LABEL_SECTION_6_TITLE,
    LABEL_SECTION_8_MADE_OF,
    LABEL_SECTION_8_SUB_TITLE_1,
    LABEL_SECTION_8_SUB_TITLE_2,
    LABEL_SECTION_8_TITLE,
    LABEL_SECTION_9_DESCRIPTION1,
    LABEL_SECTION_9_DESCRIPTION2,
    LABEL_SECTION_9_DESCRIPTION3,
    LABEL_SECTION_9_DESCRIPTION4,
    LABEL_SECTION_9_DESCRIPTION5,
    LABEL_SECTION_9_DESCRIPTION6, LABEL_SECTION_9_DESCRIPTION7,
    LABEL_SECTION_9_TITLE,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import {getIcon} from "../Libraries/Helpers/Icon";
import Button from "../Libraries/Components/Common/Button/Button";
import SectionsState from "./SectionsState";
import {useEffect, useState} from "react";
import InputLogin from "../Libraries/Components/Domain/Owner/Common/Form/Input/InputLogin";
import InputEmail from "../Libraries/Components/Domain/Owner/Common/Form/Input/InputEmail";
import InputPassword from "../Libraries/Components/Domain/Owner/Common/Form/InputPassword/InputPassword";
import {ADMIN_URL, CLIENT_URL} from "../Constants/Server";
import LocaleCache from "../Libraries/Caches/System/LocaleCache";

function Section8() {
    const {scrollToRef, section9Ref, section1Ref} = SectionsState();
    const [selectedProcess, setSelectedProcess] = useState<number|undefined>(undefined);

    return <section ref={section9Ref} className={classes(backgroundColor('primary'), textColor('ternary'), height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), padding('x', '10px'))}>
        <div className={classes(width('100%'))}>
            <div className={classes(flex("center", "center"), margin('t', '30px'))}>
                <div className={classes(padding('xy', '10px'), border(), backgroundColor("secondary"), textColor('secondary'), round())}>
                    <LabelNewLine className={classes(textAlign("center"), underline(), textSize('30px'), margin('b', '30px'))} label={LABEL_SECTION_9_TITLE}/>
                    <LabelNewLine label={LABEL_SECTION_9_DESCRIPTION1}/>
                    <LabelNewLine className={classes(margin('t', '10px'))} label={LABEL_SECTION_9_DESCRIPTION2}/>
                    <LabelNewLine label={LABEL_SECTION_9_DESCRIPTION3}/>
                    <LabelNewLine label={LABEL_SECTION_9_DESCRIPTION4}/>
                    <LabelNewLine className={classes(margin('t', '10px'))} label={LABEL_SECTION_9_DESCRIPTION5}/>
                    <LabelNewLine className={classes(margin('t', '30px'), textAlign("right"))} label={LABEL_SECTION_9_DESCRIPTION6}/>
                    <LabelNewLine className={classes(textAlign("right"))} label={LABEL_SECTION_9_DESCRIPTION7}/>
                </div>
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button onClick={() => scrollToRef(section1Ref)} label={getIcon('ChevronDoubleUp', 'medium')} color={'primary'}/>
            </div>
        </div>
    </section>;
}

export default Section8;