import {
    backgroundColor, boldText,
    border,
    classes,
    flex,
    height,
    margin,
    padding, round,
    textAlign, textColor, textSize, underline,
    width
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_SECTION_3_CREATE_OWNER_ACCOUNT_BUTTON,
    CTA_SECTION_3_TO_BO_LINK,
    CTA_SECTION_4_TO_CLIENT_LINK,
    LABEL_SECTION_3_CREATE_OWNER_SUCCESS_TIMER,
    LABEL_SECTION_3_TRY_QUESTION,
    LABEL_SECTION_4_PROCESS_ITEM_0,
    LABEL_SECTION_4_PROCESS_ITEM_1,
    LABEL_SECTION_4_PROCESS_ITEM_2,
    LABEL_SECTION_4_PROCESS_ITEM_3,
    LABEL_SECTION_4_PROCESS_ITEM_4,
    LABEL_SECTION_4_TITLE,
    LABEL_SECTION_4_TRY_QUESTION,
    LABEL_SECTION_6_DESCRIPTION,
    LABEL_SECTION_6_DESCRIPTION2,
    LABEL_SECTION_6_PROCESS_ITEM_0,
    LABEL_SECTION_6_PROCESS_ITEM_1,
    LABEL_SECTION_6_PROCESS_ITEM_2,
    LABEL_SECTION_6_PROCESS_ITEM_3,
    LABEL_SECTION_6_TITLE,
    LABEL_SECTION_8_MADE_OF,
    LABEL_SECTION_8_SUB_TITLE_1,
    LABEL_SECTION_8_SUB_TITLE_2, LABEL_SECTION_8_SUB_TITLE_3,
    LABEL_SECTION_8_TITLE, LABEL_SECTION_8_USE_LATER,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import {getIcon} from "../Libraries/Helpers/Icon";
import Button from "../Libraries/Components/Common/Button/Button";
import SectionsState from "./SectionsState";
import {useEffect, useState} from "react";
import InputLogin from "../Libraries/Components/Domain/Owner/Common/Form/Input/InputLogin";
import InputEmail from "../Libraries/Components/Domain/Owner/Common/Form/Input/InputEmail";
import InputPassword from "../Libraries/Components/Domain/Owner/Common/Form/InputPassword/InputPassword";
import {ADMIN_URL, CLIENT_URL} from "../Constants/Server";
import LocaleCache from "../Libraries/Caches/System/LocaleCache";

function Section8() {
    const {scrollToRef, section8Ref, section9Ref} = SectionsState();
    const [selectedProcess, setSelectedProcess] = useState<number|undefined>(undefined);

    return <section ref={section8Ref} className={classes(backgroundColor('ternary'), textColor('ternary'), height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), padding('x', '10px'))}>
        <div className={classes(width('100%'))}>
            <LabelNewLine className={classes(textAlign("center"), textSize('30px'), margin('t', '30px'))} label={LABEL_SECTION_8_TITLE}/>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Asana'} alt={'Asana'} src={'https://salesdorado.com/wp-content/uploads/2022/07/Asana-logo-e1657292223430.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Gitlab'} alt={'Gitlab'}  src={'https://fr.wizcase.com/wp-content/uploads/2022/03/GiTlab-lOGO.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'PHPStorm'} alt={'PHPStorm'}  src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/PhpStorm_Icon.svg/1200px-PhpStorm_Icon.svg.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <LabelNewLine className={classes(margin('t', '30px'), textAlign("center"), textSize('20px'), boldText(), underline())} label={LABEL_SECTION_8_SUB_TITLE_1}/>
            <LabelNewLine className={classes(textAlign("center"), textSize('15px'))} label={LABEL_SECTION_8_MADE_OF}/>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Javascript'} alt={'Javascript'}  src={'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Amazon Web Service'} alt={'Amazon Web Service'}  src={'https://eportfolio.u-pec.fr/artefact/file/download.php?file=25742&view=10076&embedded=1&text=40119'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'NPM'} alt={'NPM'}  src={'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Npm-logo.svg/1200px-Npm-logo.svg.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'React'} alt={'React'}  src={'https://www.spi-informatique.fr/wp-content/uploads/2018/05/React.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Storybook'} alt={'Storybook'}  src={'https://d3uyj2gj5wa63n.cloudfront.net/wp-content/uploads/2019/08/011fc620-4cb2-11e9-a51a-fdbb10b4cabb-e1567090000539.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Amazon S3'} alt={'Amazon S3'}  src={'https://fathomtech.io/blog/using-aws-s3-and-cloudfront-for-fast-static-web-sites/amazon-s3.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Amazon Route53'} alt={'Amazon Route53'}  src={'https://uploads-ssl.webflow.com/61af9eab93c48b14f5026d5c/63b47fd520e108d936ca02b4_Amazon-Route-53-Basics.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Amazon Cloudfront'} alt={'Amazon Cloudfront'}  src={'https://www.nicepng.com/png/detail/21-217813_amazon-cloudfront-logo-png-amazon-cloudfront.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'AWS Certificate Manager'} alt={'AWS Certificate Manager'}  src={'https://acloudxpert.com/wp-content/uploads/2019/03/AWS-CM.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Tailwind CSS'} alt={'Tailwind CSS'}  src={'https://i.ytimg.com/vi/Fix0niR6Hm8/maxresdefault.jpg'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <LabelNewLine className={classes(margin('t', '30px'), textAlign("center"), textSize('20px'), boldText(), underline())} label={LABEL_SECTION_8_SUB_TITLE_2}/>
            <LabelNewLine className={classes(textAlign("center"), textSize('15px'))} label={LABEL_SECTION_8_MADE_OF}/>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'PHP'} alt={'PHP'}  src={'https://static.formation.tech/formations/logos/d5e995bdc918bc780b002362c4753f48.svg'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Docker'} alt={'Docker'}  src={'https://d31ezp3r8jwmks.cloudfront.net/P4LMkQbCoYdT6NiE8ZjETEbw'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Raspberry Pi 4 - 4Go'} alt={'Raspberry Pi 4 - 4Go'}  src={'https://upload.wikimedia.org/wikipedia/fr/thumb/3/3b/Raspberry_Pi_logo.svg/1200px-Raspberry_Pi_logo.svg.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'NGINX'} alt={'NGINX'}  src={'https://fr-wiki.ikoula.com/images/4/4a/Nginx.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Symfony'} alt={'Symfony'}  src={'https://www.a5sys.com/wp-content/uploads/2021/10/symfony_logo_vertical.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Let\'s Encrypt'} alt={'Let\'s Encrypt'}  src={'https://seeklogo.com/images/L/let-s-encrypt-logo-DF90D21CBA-seeklogo.com.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'No-IP'} alt={'No-IP'}  src={'https://d2qr50rz2oof04.cloudfront.net/assets/img/logo/logo-bug.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'MySQL'} alt={'MySQL'}  src={'https://cdn2.boryl.fr/2020/12/fd8a25a0-mysql.svg'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Composer'} alt={'Composer'}  src={'https://repository-images.githubusercontent.com/1864363/49ec0c0f-21fe-40d8-bfc3-2002b0a5c1c8'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'JWT'} alt={'JWT'}  src={'https://blog.ippon.fr/content/images/2017/10/jwt.svg'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <LabelNewLine className={classes(margin('t', '30px'), textAlign("center"), textSize('20px'), boldText(), underline())} label={LABEL_SECTION_8_SUB_TITLE_3}/>
            <LabelNewLine className={classes(textAlign("center"), textSize('15px'))} label={LABEL_SECTION_8_USE_LATER}/>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Figma'} alt={'Figma'}  src={'https://reussirsaboutiqueenligne.fr/wp-content/uploads/2022/03/qu-est-ce-que-figma-un-cours-accelere-sur-le-design.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Zeplin'} alt={'Zeplin'}  src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVDpbZiVV_KlxRlfjSVCqVx57sO7n-Yzhe-g&usqp=CAU'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Salesforce'} alt={'Salesforce'}  src={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1200px-Salesforce.com_logo.svg.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Gitlab CI/CD'} alt={'Gitlab CI/CD'}  src={'https://gitedu.hesge.ch/uploads/-/system/project/avatar/3221/ci-cd.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'PHPUnit'} alt={'PHPUnit'}  src={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/PHPUnit_Logo.svg/1200px-PHPUnit_Logo.svg.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <div className={classes(margin('xy', '30px'), flex("center", "center", '10px'))}>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Mercure'} alt={'Mercure'}  src={'https://dunglas.dev/wp-content/uploads/2019/10/Capture-d%E2%80%99e%CC%81cran-2019-10-25-a%CC%80-10.46.45.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Batch'} alt={'Batch'}  src={'https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/898ba6f3-df14-44a0-8029-8d7cab2d4c62.png?auto=format&q=50&w=80&h=80&fit=max&dpr=3'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Web Browser Extensions'} alt={'Web Browser Extensions'}  src={'https://www.sos-grannygeek.com/wp-content/uploads/2021/05/chrome-installer-extension.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Behat'} alt={'Behat'}  src={'https://docs.behat.org/en/latest/_static/images/behat@2x.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Puppeteer'} alt={'Puppeteer'}  src={'https://user-images.githubusercontent.com/10379601/29446482-04f7036a-841f-11e7-9872-91d1fc2ea683.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
                <div className={classes(flex("center", "center", '10px'), width('200px'), height('100px'))}><img title={'Redis'} alt={'Redis'}  src={'https://upload.wikimedia.org/wikipedia/fr/thumb/6/6b/Redis_Logo.svg/1200px-Redis_Logo.svg.png'} className={classes(width('100%', 'max'), height('full', 'max'))}/></div>
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button onClick={() => scrollToRef(section9Ref)} label={getIcon('ChevronDoubleDown', 'medium')} color={'primary'}/>
            </div>
        </div>
    </section>;
}

export default Section8;