import {useBetween} from "use-between";
import {useRef} from "react";

function Sections() {
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);
    const section8Ref = useRef(null);
    const section9Ref = useRef(null);

    const scrollToRef = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    }

    return {scrollToRef, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref, section8Ref, section9Ref};
}

const SectionsState = () => useBetween(Sections);
export default SectionsState;