import {
    backgroundColor, boldText,
    border,
    classes,
    flex,
    height,
    margin,
    padding, round,
    textAlign, textColor
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    LABEL_SECTION_2_AFTER_ITEM_1,
    LABEL_SECTION_2_AFTER_ITEM_2,
    LABEL_SECTION_2_AFTER_ITEM_3,
    LABEL_SECTION_2_AFTER_ITEM_4, LABEL_SECTION_2_AFTER_ITEM_5,
    LABEL_SECTION_2_AFTER_TITLE,
    LABEL_SECTION_2_BEFORE_ITEM_1,
    LABEL_SECTION_2_BEFORE_ITEM_2,
    LABEL_SECTION_2_BEFORE_ITEM_3,
    LABEL_SECTION_2_BEFORE_ITEM_4,
    LABEL_SECTION_2_BEFORE_ITEM_5,
    LABEL_SECTION_2_BEFORE_TITLE,
    LABEL_SECTION_2_DESCRIPTION_1,
    LABEL_SECTION_2_DESCRIPTION_2,
    LABEL_SECTION_2_DESCRIPTION_3,
    LABEL_SECTION_2_TITLE,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import SectionsState from "./SectionsState";
import {getLabel} from "../Libraries/Helpers/Label";
import Button from "../Libraries/Components/Common/Button/Button";
import {getIcon} from "../Libraries/Helpers/Icon";

function Section2() {
    const {scrollToRef, section2Ref, section3Ref} = SectionsState();

    return <section ref={section2Ref} className={classes(backgroundColor('ternary'), textColor('ternary'), height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), padding('x', '10px'))}>
        <div>
            <LabelNewLine textSizeValue={'30px'} label={LABEL_SECTION_2_TITLE} underlineValue={true} className={classes(margin('y', '30px'), textAlign("center"))}/>
            <div className={classes(flex("center", "center"))}>
                <div className={classes(border('xy'), padding('xy', '10px'), round(), textColor('primary'), backgroundColor('secondary'))}>
                    <LabelNewLine label={LABEL_SECTION_2_DESCRIPTION_1}/>
                    <LabelNewLine label={LABEL_SECTION_2_DESCRIPTION_2}/>
                    <LabelNewLine label={LABEL_SECTION_2_DESCRIPTION_3}/>
                </div>
            </div>
            <div className={classes(flex("center", 'between', '10px', true, 'md'))}>
                <div className={classes(margin('t', '30px'), border('xy'), round())}>
                    <div className={classes(border('b'), padding('xy', '10px'), backgroundColor('secondary'), textColor('primary'), boldText(), round())}>
                        {getLabel(LABEL_SECTION_2_BEFORE_TITLE)}
                    </div>
                    <div className={classes(padding('xy','10px'), backgroundColor('primary'), textColor('primary'), textAlign('left'), round())}>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_BEFORE_ITEM_1)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_BEFORE_ITEM_2)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_BEFORE_ITEM_3)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_BEFORE_ITEM_4)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_BEFORE_ITEM_5)}</div>
                    </div>
                </div>
                <div className={classes(margin('t', '30px'), border('xy'), round())}>
                    <div className={classes(border('b'), padding('xy', '10px'), backgroundColor('secondary'), textColor('primary'), boldText(), round())}>
                        {getLabel(LABEL_SECTION_2_AFTER_TITLE)}
                    </div>
                    <div className={classes(padding('xy','10px'), backgroundColor('primary'), textColor('primary'), textAlign('left'), round())}>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_AFTER_ITEM_1)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_AFTER_ITEM_2)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_AFTER_ITEM_3)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_AFTER_ITEM_4)}</div>
                        <div className={classes(flex())}>&#9679; {getLabel(LABEL_SECTION_2_AFTER_ITEM_5)}</div>
                    </div>
                </div>
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button onClick={() => scrollToRef(section3Ref)} label={getIcon('ChevronDoubleDown', 'medium')} color={'primary'}/>
            </div>
        </div>
    </section>;
}

export default Section2;