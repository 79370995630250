import {
    backgroundColor, boldText,
    border, borderColor, borderSize,
    classes, cursor,
    flex,
    height,
    margin,
    padding, position, round,
    textAlign, textColor, textSize,
    width
} from "../Libraries/Theme/Theme";
import LabelNewLine from "../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_SECTION1_BE_OWNER,
    CTA_SECTION1_BE_USER,
    LABEL_SECTION1_DESCRIPTION,
    LABEL_SECTION1_PROCESS1_DESCRIPTION,
    LABEL_SECTION1_PROCESS2_DESCRIPTION,
    LABEL_SECTION1_PROCESS3_DESCRIPTION,
    LABEL_SECTION1_TRY,
    LABEL_SECTION_3_TITLE,
    LABEL_SECTION_5_BORIS_CONTACT,
    LABEL_SECTION_5_BORIS_EXP,
    LABEL_SECTION_5_BORIS_GRADE,
    LABEL_SECTION_5_BORIS_TITLE,
    LABEL_SECTION_5_DESIGNER_CONTACT,
    LABEL_SECTION_5_DESIGNER_EXP,
    LABEL_SECTION_5_DESIGNER_TITLE,
    LABEL_SECTION_5_DEVELOPER_CONTACT,
    LABEL_SECTION_5_DEVELOPER_EXP,
    LABEL_SECTION_5_DEVELOPER_TITLE,
    LABEL_SECTION_5_DEVOPS_CONTACT,
    LABEL_SECTION_5_DEVOPS_EXP,
    LABEL_SECTION_5_DEVOPS_TITLE,
    LABEL_SECTION_5_HIRE_TITLE,
    LABEL_SECTION_5_KAM_CONTACT,
    LABEL_SECTION_5_KAM_EXP,
    LABEL_SECTION_5_KAM_TITLE,
    LABEL_SECTION_5_NICOLAS_CONTACT,
    LABEL_SECTION_5_NICOLAS_EXP,
    LABEL_SECTION_5_NICOLAS_GRADE,
    LABEL_SECTION_5_NICOLAS_TITLE,
    LABEL_SECTION_5_TITLE,
    LABEL_SECTION_6_TITLE,
    LABEL_SECTION_7_DESCRIPTION,
    LABEL_SECTION_7_MAIL_DESCRIPTION,
    LABEL_SECTION_7_MAIL_DESCRIPTION2,
    LABEL_SECTION_7_TITLE,
    LABEL_SLOGAN
} from "../Libraries/Constants/Labels";
import {getIcon} from "../Libraries/Helpers/Icon";
import Button from "../Libraries/Components/Common/Button/Button";
import SectionsState from "./SectionsState";

function Section7() {
    const {scrollToRef, section7Ref, section8Ref} = SectionsState();

    return <section ref={section7Ref} className={classes(backgroundColor('primary'), textColor('primary'), height("100vh-100px", 'min'), flex("center", "center"), textAlign("center"), padding('x', '10px'), padding('b', '30px'))}>
        <div className={classes(width('100%'))}>
            <LabelNewLine className={classes(textAlign("center"), textSize('30px'), margin('t', '30px'))} label={LABEL_SECTION_7_TITLE}/>
            <LabelNewLine className={classes(textAlign("center"), textSize('20px'))} label={LABEL_SECTION_7_MAIL_DESCRIPTION}/>
            <LabelNewLine className={classes(boldText(), textAlign("center"), textSize('20px'), margin('xy', '30px'))} label={LABEL_SECTION_7_MAIL_DESCRIPTION2}/>
            <div className={classes(flex("center", "center"), margin('t', '30px'))}>
                <div className={classes(padding('xy', '20px'), border(), backgroundColor("secondary"), round())}>
                    <LabelNewLine className={classes(textAlign("center"), textSize('30px'))} label={'Queube'}/>
                    <LabelNewLine className={classes(textAlign("center"), textSize('20px'))} label={LABEL_SLOGAN}/>
                </div>
            </div>
            <div className={classes(margin('y', '30px'), flex("center", "center"))}>
                <Button type={"submit"} onClick={() => scrollToRef(section8Ref)} label={getIcon('ChevronDoubleDown', 'medium')} color={'ternary'}/>
            </div>
        </div>
    </section>;
}

export default Section7;