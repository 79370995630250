import React, {ReactElement, useContext} from "react";
import {
    backgroundColor,
    borderColor,
    classes,
    flex,
    height, margin,
    textColor, textSize,
    width
} from "../../Libraries/Theme/Theme";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import LocaleModal from "../../Libraries/Components/Common/Modal/LocaleModal";
import {State} from "../Menu/State";

interface PropsInterface {
    children: ReactElement|string;
}

export interface ChildPropsInterface {
    children: ReactElement|string;
    centeredContent: boolean;
}

function PageTemplate({children}: PropsInterface) {
    const {isOpened} = State();

    return <div className={`${classes(height('100vh', "min"), backgroundColor(), flex(), width('100%'), textColor(), textSize("15px"))}`}>
        <LocaleModal/>
        <Menu/>
        <div className={classes(width('100%'), !isOpened ? '' : margin('l', '300px', 'md'))}>
            <div className={`${classes(borderColor(), height('70px'))}`}>
                <Header/>
            </div>
            <div className={classes(height('100vh-90px'))}>
                {children}
            </div>
        </div>
    </div>;
}

export default PageTemplate;